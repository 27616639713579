import { Auth0Provider } from "@auth0/auth0-react";
import { AuthCheck } from "@features/authentication";

const AuthenicationContainer = ({ children }) => (
	<Auth0Provider
		domain="ring.uk.auth0.com"
		clientId="74GKYBb8WnWXgXuWjZxbIXOenmcsaLx6"
		authorizationParams={{
			redirect_uri: `${window.location.origin}`,
			audience: "https://ring.stuntbaboon.com",
			scope: "read:assets",
		}}
		useRefreshTokens
		useRefreshTokensFallback
		cacheLocation="localstorage"
	>
		<AuthCheck>{children}</AuthCheck>
	</Auth0Provider>
);

export { AuthenicationContainer };
